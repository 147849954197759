import * as React from "react";

const IndexPage = () => {
  return (
    <div>
      <p>In progress...</p>
    </div>
  )
}

export default IndexPage
